import React, { useEffect, useState } from 'react'
import { DocumentCard } from '@fluentui/react'

import { teamsLightTheme, Body1Strong, Divider } from '@fluentui/react-components'

import { FluentProvider } from '@fluentui/react-components'

import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleEventHandler,
} from '@fluentui/react-components'
import { Heart24Regular } from '@fluentui/react-icons'
import { Clock24Regular, Headphones24Regular } from '@fluentui/react-icons'
import Header from '../../components/header'
import InterestPreferences from './InterestPreferences'
import WorkingHours from './WorkingHours'
import MeetingFrequency from './MeetingFrequency'
import AdminConfig from './AdminConfig'
import { useAppStateStore } from '../../store'
import { apiGet, apiPost } from '../../services/fetch/apiClient'
import { Breadcrumb } from '@fluentui/react/lib/Breadcrumb'
import Reports from './Reports'
import { logger } from '../../components/App'
import moment from 'moment'

type InterestModel = {
  id: string
  name: string
}

type UserPreferenceModel = {
  workingHours: {
    from: number
    until: number
  }
  nextMeetingInDays: number
  interests: InterestModel[]
}

export default function Preference() {
  const appState = useAppStateStore()
  const [openItems, setOpenItems] = React.useState(['1'])
  const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
    setOpenItems(data.openItems)
  }
  useEffect(() => {
    getUserPreferences();
  }, [])
  
  const updateInterestPreference = async (id: string, isUserSubscribed: boolean) => {
    try {
      await apiPost('settings/set-interest', appState.identity.accessToken, {
        interestId: id,
        on: isUserSubscribed,
      })

      appState.updateInterest(id, isUserSubscribed)
    } catch (err: any) {
      logger.debug(err)
    }
  }

  const formattedTime = (hours: number) => {
    const date = new Date()
    date.setHours(hours, 0, 0, 0)
    return moment(date).format('hh A')
  }

  const getUserPreferences = async () => {
    logger.debug('getUserPreferences')

    try {
      const activeInterests = await apiGet<InterestModel[]>(
        'interests/get-active-interests',
        appState.identity.accessToken
      )
      activeInterests.map((interest: InterestModel) => {
        appState.addInterest(interest.id, interest.name)
      })

      const userPreference = await apiGet<UserPreferenceModel>('settings', appState.identity.accessToken)
      appState.setUserPreference(userPreference)
    } catch (err: any) {
      logger.debug(err)
    }
  }
  
  return (
    <FluentProvider theme={teamsLightTheme}>
      <div style={{ backgroundColor: '#EFF5F9' }}>
        <Header />

        <div style={{ marginLeft: '2.5rem', marginBottom: '1rem' }}>
          <Breadcrumb
            items={[
              { text: 'Home', key: 'home', href: '#/tab' },
              { text: 'Preferences', key: 'preferences', isCurrentItem: true },
            ]}
          />
        </div>

        <div style={{ marginLeft: '3rem' }}>
          <Body1Strong>Edit Perference</Body1Strong>
          <DocumentCard
            style={{
              maxWidth: '100%',
              height: 'auto',
              padding: '0.7rem',
              border: '1px solid #F9F9F9',
              borderRadius: '0.5rem',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: '1rem',
              boxShadow: '0 3px 3px 0 rgba(0, 0, 0, .13)',
              justifyContent: 'space-between',
              marginLeft: '0.5rem',
              marginRight: '2.5rem',
              marginTop: '0.6rem',
            }}
          >
            <Accordion openItems={openItems} onToggle={handleToggle} collapsible style={{ width: '100%' }}>
              <AccordionItem value="1">
                <AccordionHeader
                  icon={<Heart24Regular />}
                  size="large"
                  expandIconPosition="end"
                  style={{ backgroundColor: openItems.includes('1') ? '#f5f5f5' : 'white' }}
                >
                  <span className="ms-fontWeight-semibold">
                    Select Your Interests
                    <div>
                      {' '}
                      <span className="ms-fontWeight-regular ms-fontSize-12	" style={{ color: '#616161' }}>
                        {appState.selectedInterestsCount > 0 ? appState.selectedInterestsCount.toString() : 'none'}{' '}
                        selected
                      </span>
                    </div>
                  </span>
                </AccordionHeader>

                <AccordionPanel>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <InterestPreferences
                      interests={Array.from(appState.interests.values())}
                      onChangePreference={updateInterestPreference}
                    />
                  </div>
                </AccordionPanel>
              </AccordionItem>
              <Divider style={{ padding: '0.4rem' }} appearance="subtle"></Divider>

              <AccordionItem value="2">
                <AccordionHeader
                  size="large"
                  icon={<Clock24Regular />}
                  expandIconPosition="end"
                  style={{ backgroundColor: openItems.includes('2') ? '#f5f5f5' : 'white' }}
                >
                  <span className="ms-fontWeight-semibold">
                    Working Hours
                    <div>
                      {' '}
                      <span className="ms-fontWeight-regular ms-fontSize-12	" style={{ color: '#616161' }}>
                        {formattedTime(appState.profile.workingHours.from)} -{' '}
                        {formattedTime(appState.profile.workingHours.until)}
                      </span>
                    </div>
                  </span>
                </AccordionHeader>
                <AccordionPanel>
                  <WorkingHours />
                </AccordionPanel>
              </AccordionItem>
              <Divider style={{ padding: '0.4rem' }} appearance="subtle"></Divider>

              <AccordionItem value="3">
                <AccordionHeader
                  size="large"
                  icon={<Headphones24Regular />}
                  expandIconPosition="end"
                  style={{ backgroundColor: openItems.includes('3') ? '#f5f5f5' : 'white' }}
                >
                  <span className="ms-fontWeight-semibold">
                    Meeting Frequency
                    <div>
                      <span className="ms-fontWeight-regular ms-fontSize-12	" style={{ color: '#616161' }}>
                        {appState.profile.meetingFrequency === 1 && 'Daily'}
                        {appState.profile.meetingFrequency === 2 && 'Alternate Days'}
                        {appState.profile.meetingFrequency === 7 && 'Weekly'}
                      </span>
                    </div>
                  </span>
                </AccordionHeader>
                <AccordionPanel>
                  <MeetingFrequency />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </DocumentCard>

          {appState.identity.isAdmin && (
            <div style={{ marginTop: '2rem' }}>
              <Body1Strong>Admin</Body1Strong>
              <DocumentCard
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  padding: '0.7rem',
                  border: '1px solid #F9F9F9',
                  borderRadius: '0.5rem',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: '1rem',
                  boxShadow: '0 3px 3px 0 rgba(0, 0, 0, .13)',
                  justifyContent: 'space-between',
                  marginLeft: '0.5rem',
                  marginRight: '2.5rem',
                  marginTop: '0.6rem',
                  marginBottom: '1rem',
                }}
              >
                <AdminConfig />
              </DocumentCard>
            </div>
          )}

          {appState.identity.isAdmin && (
            <div style={{ marginTop: '2rem' }} className="ms-hiddenLg">
              <Body1Strong>Reports</Body1Strong>
              <DocumentCard
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  padding: '0.7rem',
                  border: '1px solid #F9F9F9',
                  borderRadius: '0.5rem',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: '1rem',
                  boxShadow: '0 3px 3px 0 rgba(0, 0, 0, .13)',
                  justifyContent: 'space-between',
                  marginLeft: '0.5rem',
                  marginRight: '2.5rem',
                  marginTop: '0.6rem',
                  marginBottom: '1.5rem',
                }}
              >
                <Reports />
              </DocumentCard>
            </div>
          )}
        </div>
      </div>
    </FluentProvider>
  )
}
