import { Field } from '@fluentui/react-components'
import { TimePicker, TimePickerProps, formatDateToTimeString } from '@fluentui/react-timepicker-compat'
import { useState } from 'react'
import { useAppStateStore } from '../../store'
import moment from 'moment'
import { apiPost } from '../../services/fetch/apiClient'
import { logger } from '../../components/App'

export default function WorkingHours() {
  const [availableFromError, setAvailableFromError] = useState<string>('')
  const [availableUntilError, setAvailableUntilError] = useState<string>('')

  const appState = useAppStateStore()

  const dateFromHour = (hour: number) => {
    return new Date(moment().set({ hour: hour, minute: 0 }).format('YYYY-MM-DD HH:mm'))
  }

  const setWorkingHours = async (from: number, until: number) => {
    try {
      await apiPost('settings/set-working-hours', appState.identity.accessToken, {
        availableFrom: from,
        availableUntil: until,
      })

      appState.setWorkingHours(from, until)
    } catch (err: any) {
      logger.debug(err)
    }
  }

  const onAvailableFromChange: TimePickerProps['onTimeChange'] = (_ev, data) => {
    const fromHour = moment(data.selectedTime).hour()
    if (appState.profile.workingHours.until <= fromHour) {
      setAvailableFromError('From time should be lesser than To time')
      return
    }

    setAvailableFromError('')
    setWorkingHours(fromHour, appState.profile.workingHours.until)
  }

  const onAvailableUntilChange: TimePickerProps['onTimeChange'] = (_ev, data) => {
    const untilHour = moment(data.selectedTime).hour()
    if (untilHour <= appState.profile.workingHours.from) {
      setAvailableUntilError('To time should be greater than From time')
      return
    }

    setAvailableUntilError('')
    setWorkingHours(appState.profile.workingHours.from, untilHour)
  }

  return (
    <>
      <div style={{ display: 'inline-flex', gap: '4rem' }}>
        <Field label="From" validationMessage={availableFromError}>
          <TimePicker
            increment={60}
            hourCycle="h11"
            freeform={false}
            defaultSelectedTime={dateFromHour(appState.profile.workingHours.from)}
            defaultValue={formatDateToTimeString(dateFromHour(appState.profile.workingHours.from))}
            onTimeChange={onAvailableFromChange}
          />
        </Field>
        <Field label="To" validationMessage={availableUntilError}>
          <TimePicker
            increment={60}
            hourCycle="h11"
            freeform={false}
            defaultSelectedTime={dateFromHour(appState.profile.workingHours.until)}
            defaultValue={formatDateToTimeString(dateFromHour(appState.profile.workingHours.until))}
            onTimeChange={onAvailableUntilChange}
          />
        </Field>
      </div>
    </>
  )
}
