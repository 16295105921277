import { DocumentCard } from '@fluentui/react'
import Header from '../../components/header'
import { FluentProvider, ToggleButton, teamsLightTheme, Option, Dropdown } from '@fluentui/react-components'
import { Filter24Regular } from '@fluentui/react-icons'
import { useAppStateStore } from '../../store'
import { useEffect, useState } from 'react'
import Meeting from './meeting'
import { Breadcrumb } from '@fluentui/react/lib/Breadcrumb'
import { apiGet } from '../../services/fetch/apiClient'
import { logger } from '../../components/App'
import _ from 'lodash'
import moment from 'moment'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import 'animate.css/animate.css'
export interface IAttendeeInfo {
  attendeeId: string
  attendeeName: string
  attended: boolean
}

export interface IMeetingInfo {
  attendees: IAttendeeInfo[]
  interestId: string
  interestName: string
  meetingId: string
  meetingTimeUtc: string
}

export default function Reports() {
  const [meetings, setMeetings] = useState<IMeetingInfo[]>([])
  const [filteredMeetings, setFilteredMeetings] = useState<IMeetingInfo[]>([])
  const [users, setUsers] = useState<Map<string, string>>(new Map<string, string>())
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [interests, setInterests] = useState<Map<string, string>>(new Map<string, string>())
  const [selectedInterests, setSelectedInterests] = useState<string[]>([])
  const [activeTab, setActiveTab] = useState<number>(7)
  const [meetingsByInterestLookup, setMeetingsByInterestLookup] = useState<Map<string, Set<string>>>(
    new Map<string, Set<string>>()
  )
  const [meetingsByUserLookup, setmeetingsByUserLookup] = useState<Map<string, Set<string>>>(
    new Map<string, Set<string>>()
  )

  const appState = useAppStateStore()

  useEffect(() => {
    loadMeetings(activeTab)
  }, [activeTab])

  useEffect(() => {
    filterMeetings()
  }, [selectedInterests])

  useEffect(() => {
    filterMeetings()
  }, [selectedUsers])

  const filterMeetings = () => {
    if (selectedInterests.length === 0 && selectedUsers.length === 0) {
      setFilteredMeetings(meetings)
      return
    }

    const meetingIds = new Set<string>()
    selectedInterests.forEach((interestId) => {
      meetingsByInterestLookup.get(interestId)!.forEach((meetingId) => {
        meetingIds.add(meetingId)
      })
    })

    selectedUsers.forEach((userId) => {
      meetingsByUserLookup.get(userId)!.forEach((meetingId) => {
        meetingIds.add(meetingId)
      })
    })

    const results: IMeetingInfo[] = []
    meetings.forEach((meeting) => {
      if (meetingIds.has(meeting.meetingId)) {
        results.push(meeting)
      }
    })

    setFilteredMeetings(results)
  }

  const loadMeetings = async (numberOfDays: number) => {
    try {
      const meetingsResult = await apiGet<IMeetingInfo[]>(
        `dashboard/events/${numberOfDays}`,
        appState.identity.accessToken
      )

      setMeetings(meetingsResult)
      setFilteredMeetings(meetingsResult)

      const uniqueInterests = new Map<string, string>()
      const uniqueUsers = new Map<string, string>()
      const meetingsByInterest = new Map<string, Set<string>>()
      const meetingsByUser = new Map<string, Set<string>>()

      meetingsResult.forEach((meeting) => {
        uniqueInterests.set(meeting.interestId, meeting.interestName)

        if (meetingsByInterest.has(meeting.interestId)) {
          meetingsByInterest.set(meeting.interestId, meetingsByInterest.get(meeting.interestId)!.add(meeting.meetingId))
        } else {
          meetingsByInterest.set(meeting.interestId, new Set([meeting.meetingId]))
        }

        meeting.attendees.forEach((attendee) => {
          uniqueUsers.set(attendee.attendeeId, attendee.attendeeName)

          if (meetingsByUser.has(attendee.attendeeId)) {
            meetingsByUser.set(attendee.attendeeId, meetingsByUser.get(attendee.attendeeId)!.add(meeting.meetingId))
          } else {
            meetingsByUser.set(attendee.attendeeId, new Set([meeting.meetingId]))
          }
        })
      })

      setInterests(uniqueInterests)
      setUsers(uniqueUsers)
      setMeetingsByInterestLookup(meetingsByInterest)
      setmeetingsByUserLookup(meetingsByUser)
    } catch (err: any) {
      logger.debug(err)
    }
  }

  const onUserSelect = (ev: any, data: any) => {
    setSelectedUsers(data.selectedOptions)
  }

  const onInterestSelect = (ev: any, data: any) => {
    setSelectedInterests(data.selectedOptions)
  }

  const onSetActiveTab = (numberOfDays: number) => {
    setActiveTab(numberOfDays)
    setSelectedUsers([])
    setSelectedInterests([])
  }

  return (
    <FluentProvider theme={teamsLightTheme}>
      <div style={{ backgroundColor: '#EFF5F9' }}>
        <Header />

        <div style={{ marginLeft: '2rem', marginBottom: '1rem', marginTop: '-3rem' }}>
          <Breadcrumb
            items={[
              { text: 'Home', key: 'home', href: '#/tab' },
              { text: 'Preferences', key: 'preferences', href: '#/preference' },
              { text: 'Meetings Report', key: 'meetingsReport', isCurrentItem: true },
            ]}
          />
        </div>

        <div style={{ marginLeft: '2.5rem', marginRight: '4rem' }}>
          <DocumentCard
            style={{
              maxWidth: '100%',
              width: '100%',
              height: '3.5rem',
              padding: '1rem',
              border: '1px solid #F9F9F9',
              borderRadius: '0.5rem',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: '1rem',
              boxShadow: '0 3px 3px 0 rgba(0, 0, 0, .13)',
            }}
          >
            <div>
              <ToggleButton
                checked={activeTab === 7}
                onClick={() => {
                  onSetActiveTab(7)
                }}
                style={{
                  backgroundColor: activeTab === 7 ? '#1b75d0' : 'white',
                  color: activeTab === 7 ? 'white' : 'black',
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                7 Days
              </ToggleButton>
              <ToggleButton
                checked={activeTab === 30}
                onClick={() => {
                  onSetActiveTab(30)
                }}
                style={{
                  backgroundColor: activeTab === 30 ? '#1b75d0' : 'white',
                  color: activeTab === 30 ? 'white' : 'black',
                  borderRadius: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 0,
                }}
              >
                30 Days
              </ToggleButton>
              <ToggleButton
                checked={activeTab === 60}
                onClick={() => {
                  onSetActiveTab(60)
                }}
                style={{
                  backgroundColor: activeTab === 60 ? '#1b75d0' : 'white',
                  color: activeTab === 60 ? 'white' : 'black',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                60 Days
              </ToggleButton>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>
                <Filter24Regular />
              </span>
              <span style={{ fontWeight: '500' }}>Filter by:</span>
            </div>
            <div style={{ display: 'inline-flex' }}>
              <Dropdown
                aria-labelledby="usersDropDown"
                multiselect={true}
                selectedOptions={selectedUsers}
                onOptionSelect={onUserSelect}
                placeholder="users"
              >
                {Array.from(users).map(([userId, userName]) => (
                  <Option value={userId}>{userName}</Option>
                ))}
              </Dropdown>
            </div>

            <div style={{ display: 'inline-flex' }}>
              <Dropdown
                aria-labelledby="interestsDropDown"
                multiselect={true}
                selectedOptions={selectedInterests}
                onOptionSelect={onInterestSelect}
                placeholder="interests"
              >
                {Array.from(interests).map(([interestId, interestName]) => (
                  <Option value={interestId}>{interestName}</Option>
                ))}
              </Dropdown>
            </div>

            {(selectedUsers.length > 0 || selectedInterests.length > 0) && (
              <div style={{ display: 'inline-flex' }}>
                <ToggleButton
                  onClick={() => {
                    setSelectedUsers([])
                    setSelectedInterests([])
                  }}
                  style={{
                    backgroundColor: 'white',
                    color: 'black',
                  }}
                >
                  Clear Filters
                </ToggleButton>
              </div>
            )}
          </DocumentCard>
          <div style={{ marginTop: '2rem' }}>
            {_.chain(filteredMeetings)
              .groupBy((meeting) => new Date(meeting.meetingTimeUtc).toLocaleDateString())
              .map((values, key) => ({ key, meetings: values }))
              .value()
              .map((meetingsGroup) => (
                <>
                  <span
                    // className="ms-fontWeight-semibold animate__animated animate__fadeInRightBig "
                    className={`ms-fontWeight-semibold animate__animated ${
                      activeTab === 7
                        ? 'animate__fadeInLeftBig'
                        : activeTab === 30
                        ? 'animate__fadeInLeft'
                        : 'animate__fadeInTopRight'
                    }`}
                    style={{ fontSize: '12px', color: '#252424' }}
                  >
                    {moment(meetingsGroup.meetings[0].meetingTimeUtc).format('MMMM Do YYYY')}
                  </span>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      paddingTop: '0.5rem',
                      flexWrap: 'wrap',
                      gap: '1rem',
                    }}
                    className={`animate__animated ${
                      activeTab === 7
                        ? 'animate__fadeInLeftBig '
                        : activeTab === 30
                        ? 'animate__fadeInLeft'
                        : 'animate__slideInLeft'
                    }`}
                    // className="animate__animated animate__fadeInLeftBig  "
                    // className={`animate__animated ${
                    //   activeTab === 7 || activeTab === 30 ? 'animate__fadeInLeft' : 'animate__fadeInLeft'
                    // }`}
                  >
                    {meetingsGroup.meetings.map((meeting, index) => (
                      <CSSTransition key={index} timeout={500}>
                        <Meeting
                          attendees={meeting.attendees}
                          interestName={meeting.interestName}
                          meetingTimeUtc={meeting.meetingTimeUtc}
                          interestId={meeting.interestId}
                          meetingId={meeting.meetingId}
                        />
                      </CSSTransition>
                    ))}

                    <div style={{ marginBottom: '3rem' }}></div>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
    </FluentProvider>
  )
}
